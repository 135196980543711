import { safeRound } from '@utils/safeRound'

import formatNumberToCurrency from './formatNumberToCurrency'

export function truncateToOneDecimalPlace(value: number) {
    if (value == null) {
        return 'N/A'
    }

    return safeRound(value, 1)
}

export const getFormattedMonth = (
    value?: number,
    format: 'short' | 'long' = 'short'
): string => {
    if (value == null) {
        return 'N/A'
    }

    const monthText = format === 'short' ? 'mth' : 'month'

    if (value === 1) {
        return `${value} ${monthText}`
    }

    return `${value} ${monthText}s`
}

export const getFormattedAPR = (
    value?: number,
    useGuaranteedAPR?: boolean,
    guaranteedAPR?: number
): string => {
    if (value == null) {
        return 'N/A'
    }

    if (useGuaranteedAPR && guaranteedAPR) {
        return `${guaranteedAPR.toFixed(1)}%`
    }

    return `${value}%`
}

export const getFormattedCurrency = (amount?: number): string => {
    return formatNumberToCurrency(amount)
}

export const getFormattedInterestRate = (value: number): string => {
    if (Number.isInteger(value)) {
        return truncateToOneDecimalPlace(value)
    }
    return value?.toString()
}
