import { LinkProps } from 'next/link'

import { LinkButtonProps } from '@totallymoney/ui/components/LinkButton/LinkButton'

import { StyledNextLink } from './NextLinkButton.styled'

interface NextLinkButtonProps
    extends Omit<LinkButtonProps, 'href' | 'variant'> {
    shallow?: boolean
    to: LinkProps['href']
    variant?: LinkButtonProps['variant']
}

const NextLinkButton = ({
    shallow = true,
    text,
    to,
    variant = 'tertiaryTransparent',
    ...props
}: NextLinkButtonProps) => (
    <StyledNextLink href={to} shallow={shallow} variant={variant} {...props}>
        {text}
    </StyledNextLink>
)

export default NextLinkButton
