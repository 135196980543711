import { useState } from 'react'

import TotallyMoneySymbolOnly from '@totallymoney/ui/brandassets/logos/TotallyMoneySymbolOnly'
import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import LinkButton from '@totallymoney/ui/components/LinkButton/LinkButton'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import Icon from '@totallymoney/ui/icons/Icon'

import AppAdoptionHeaderBackground from '../../public/images/bg-squiggle.png'

const AppAdoptionHeader = ({ customerId }: { customerId?: string }) => {
    const [showAppAdoptionHeader, setShowAppAdoptionHeader] = useState(true)

    return showAppAdoptionHeader && customerId ? (
        <Stack
            p="space30"
            pb="space20"
            backgroundColor="productNeutral120"
            backgroundImage={`url(${AppAdoptionHeaderBackground?.src})`}
            style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
            space="space20"
        >
            <Box display="flex" justifyContent="space-between">
                <Box width="space50">
                    <TotallyMoneySymbolOnly />
                </Box>

                <Box onClick={() => setShowAppAdoptionHeader(false)}>
                    <Icon icon="close" size={20} />
                </Box>
            </Box>

            <Box>
                <Heading variant="60">Switch to the app</Heading>

                <Text variant="100">
                    View all card offers available with our app.
                </Text>
            </Box>

            <LinkButton
                href={`https://totallymoney.app.link/rVTn5VO5dAb/?customerId=${customerId}`}
                variant="secondaryOutline"
                text="Open the app"
            />
        </Stack>
    ) : null
}

export default AppAdoptionHeader
