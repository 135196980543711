import { safeRound } from '@utils/safeRound'

import { ContentProps, ProductDetailType } from './Content.interface'

const ContentGuaranteedExample = ({
    creditCard,
    renderProductDetail,
}: ContentProps) => {
    const guaranteedAPR = creditCard.guaranteedRate.guaranteedAPR

    if (guaranteedAPR == null || guaranteedAPR <= 0) {
        return null
    }

    return renderProductDetail({
        title: `Guaranteed % APR (${
            creditCard.isFixedRate ? 'fixed' : 'variable'
        })`,
        value: `${safeRound(guaranteedAPR, 1)}% APR`,
        detailType: ProductDetailType.GuaranteedExample,
    })
}

export default ContentGuaranteedExample
